import styled from "styled-components";

const Container = styled.div`
  //   display: flex;
  //   justify-content: flex-end;
  padding: 20px;
  min-width: 380px;
  max-width: 430px;
  @media (max-width: 720px) {
    padding: initial;
    min-width: initial;
    max-width: initial;
  }
`;

const BoxText = styled.div`
  margin-bottom: 25px;
`;

const ContainerLogo = styled.div`
  display: flex;
  justify-content: center;
`;

export { Container, BoxText, ContainerLogo };
